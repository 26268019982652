<template>
  <div id="404">
    <vue-headful
            title="404 - Coffee Avenue"
            description="We are a local coffee shop based in San Francisco, CA. Come visit us!"
    />
    <Navbar></Navbar>

    <div class="section1 pb-4" style="padding-top:16% !important;margin-bottom: 1%;">

      <div class="container content fade-in" style="margin-bottom: 5%;">
        <h1 class="title heading">
          Page Not Found!
        </h1>
        <div class="wrap">
        <h2 class="subtitle">Sorry 'bout that we're all out of milk!</h2>
        </div>
          <div class="row mt-4">
            <div class="col-sm">
              <p>
                <!-- Come visit or pick up an order, we're open from 8am to 6pm.<br>
                513 Broadway, Millbrae, CA 94030 -->
              </p>
            </div>
            <div class="col-sm">
              <p>
                
              </p>
            </div>
          </div>
          <!-- <a href="#" target="_blank">
            <i class="fab fa-facebook fa-3x" style="color:white;"></i>
          </a> -->

      </div>

    </div>

    <!-- <div class="arrow bounce">
      <a class="fa fa-arrow-down fa-2x" href="#"></a>
    </div> -->

    
    <!-- <div class="section1 pb-4">

      <div class="container content fade-in-left" style="margin-bottom: 5%;">
        <h1 class="title heading">Client Testimonials</h1>
        
        <div class="row pt-4">
            <div class="col">

              <div class="card" style="width: 18rem;">
                <div class="card-body">
                  <p class="card-text dark-text">
                    I have been visiting Barry since I relocated to Basel 2 years ago, 
                    and I wouldn’t go anywhere else! Barry is not only a phenomenal hair dresser, but I thoroughly enjoy the conversation and the travel tips. 
                    I highly, highly recommend a cut and color with Barry!
                  </p>
                  <h5 class="card-title dark-text">- Kristen Wall</h5>
                </div>
              </div>

            </div>
            <div class="col" style="">
              
              <div class="card" style="width: 18rem;background-color:black;">
                <div class="card-body">
                  <p class="card-text">
                    Barry is the absolute best- a genius with hair and a delight to speak with. 
                    I just had my first appointment with him and I will definitely be back. 
                    I’d been nervous to get my hair cut, but Barry put me at ease and mastered the classy look I was hoping for. 
                    Thanks Barry!!!
                  </p>
                  <h5 class="card-title">- Elisa Galey</h5>
                </div>
              </div>

            </div>
            <div class="col">
              
              <div class="card" style="width: 18rem;">
                <div class="card-body">
                  <p class="card-text dark-text">
                    I highly recommend Hair Design by Barry! 
                    Barry is very professional and highly talented, with many years of experience and training in the business. 
                    He is amazing at what he does! His haircut always brings out the best in my naturally curly hair even with low maintenance.
                  </p>
                  <h5 class="card-title dark-text">- Maria Santos</h5>
                </div>
              </div>

            </div>
          </div>
          <div class="devider"></div>
          <a href="#" class="btn btn-info">Read More</a> 

      </div>

    </div> -->



  <Footer></Footer>

  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style>
.content{
  padding-top:2%;
}
.title{
  font-size: 50px;
}
.subtitle{
  font-size: 25px;
}
.highlight{
  background-color: #fff;
  color: #000 !important;
  padding: 5px 5px 5px 5px;
  max-width: 350px;
}

.btn-info {
  background: rgba(0, 0, 0, 1);
  color: white;
  border: #000 solid 1px;
  padding: 8px 15px 8px 15px;
  border-radius: 0.2rem;
  transition: 0.2s;
}
.btn-info:hover {
  opacity: 0.7;
  color: white;
  background-color: rgba(84, 89, 95, 1);
  border-color: #54595f;
  text-decoration: none;
  cursor: pointer;
}
.btn-info:focus {
  opacity: 0.7;
  color: #54595f;
  background-color: #ffffff !important;
  border-color: #000;
  box-shadow: 0 0 0 0.2rem #54595f;
  text-decoration: none;
  cursor: pointer;
}
.devider{
  margin-top: 2%;
  margin-bottom: 2%;
}
</style>
